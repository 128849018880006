export enum LocalStorageDataEnum {
  AVATAR_USER_CONFIG_LIST = 'avatar-user-config-list',
  BOARD_BUILDER = 'board-builder-configuration',
  BOARD_BUILDER_SORTING_KEY = 'board-sorting-state-',
  BOARD_SCHEDULE_CELL = 'schedule-cell',
  CALENDAR = 'calendar-configuration',
  KANBAN_ACTIVE_SECTIONS = 'kanban-active-sections',
  SETTINGS_TURNOVER_ELAPSED_DAYS_TOGGLE = 'settings-turnover-elapsed-days-toggle',
  SETTINGS_TURNOVER_STEP_ASSIGNEES_TOGGLE = 'settings-turnover-step-assignees-toggle',
  SETTINGS_CUSTOM_FIELDS_TOGGLE = 'settings-custom-fields-toggle',
  SETTINGS_WORKFLOW_STEPS_TOGGLE = 'settings-workflow-steps-toggle',
  CACHE_KEYS = 'cache-keys-{0}',
  //INSPECTION
  INSPECTION_LIST_SORT_ACTIVE = 'inspection-list-sort-active',
  INSPECTION_LIST_SORT_DIRECTION = 'inspection-list-sort-direction',
  INSPECTION_LIST_MODE = 'inspection-list-mode',
  INSPECTION_LIST_FILTER = 'inspection-list-filter-updated',
  INSPECTION_SECTION_STATES = 'inspection-section-states',
  //SERVICE
  SERVICE_LIST_FILTER = 'service-list-filter',
  SERVICE_LIST_SORT_ACTIVE = 'service-list-sort-active',
  SERVICE_LIST_SORT_DIRECTION = 'service-list-sort-direction',
  SERVICE_LIST_TICKETS_TOGGLE = 'service-list-tickets-toggle',

  InspectionFilterOffsetKey = '[inspections]_filter_offset',
  TurnoverFilterOffsetKey = '[turnover_list]_filter_offset',
  ReportRocFilterOffsetKey = '[report_roc]_filter_offset',
  ReportRentRollFilterOffsetKey = '[report_rent-roll]_filter_offset',
  BoardBuilderOffset = '[board_builder]_filter_offset',
  BoardBuilderResize = '[board_builder]_resize;id=',

  // report tables
  ReportTimeTableFilterState = '[reports]_time',

  //TEMPLATE MANAGER
  TEMPLATE_MANAGER_ACTIVE_TAB = 'template-manager-active-tab',

  SETTINGS_MAPPING_STATUSES_TOGGLE = 'SETTINGS_MAPPING_STATUSES_TOGGLE',
  SETTINGS_MAPPING_CATEGORIES_TOGGLE = 'SETTINGS_MAPPING_CATEGORIES_TOGGLE',
  SETTINGS_MAPPING_USERS_TOGGLE = 'SETTINGS_MAPPING_USERS_TOGGLE',
  SETTINGS_MAPPING_PRIORITIES_TOGGLE = 'SETTINGS_MAPPING_PRIORITIES_TOGGLE',
  SETTINGS_MAPPING_SYNC_OPTIONS_TOGGLE = 'SETTINGS_MAPPING_SYNC_OPTIONS_TOGGLE',
  SETTINGS_MAPPING_ORIGINS_TOGGLE = 'SETTINGS_MAPPING_ORIGINS_TOGGLE',
  SETTINGS_MAPPING_FAILOVER_IMPORT_TOGGLE = 'SETTINGS_MAPPING_FAILOVER_IMPORT_TOGGLE',
  SETTINGS_MAPPING_FAILOVER_EXPORT_TOGGLE = 'SETTINGS_MAPPING_FAILOVER_EXPORT_TOGGLE',

  // TURNOVER ARCHIVE
  TURNOVER_ARCHIVE_LIST_SORT_ACTIVE = 'turnover-archive-list-sort-active',
  TURNOVER_ARCHIVE_LIST_SORT_DIRECTION = 'turnover-archive-list-sort-direction',
}
