import { ManagementRoute } from '@shared/enums/management-route.enum';
import { RoutePath } from '@shared/enums/route-path.enum';

export const AppRoutes = {
  HOME: `/${RoutePath.HOME}`,
  ROLES: `/${RoutePath.ROLES}`,
  ROLES_ADMIN_HOME: `/${RoutePath.ROLES}/${RoutePath.ADMIN}`,
  ROLES_ADMIN_PERFORMANCE_METRICS: `/${RoutePath.ROLES}/${RoutePath.ADMIN}/${RoutePath.PERFORMANCE_METRICS}`,
  ROLES_ASSET_HOME: `/${RoutePath.ROLES}/${RoutePath.ASSET_DASHBOARD}`,
  ROLES_ASSET_PROPERTY: `/${RoutePath.ROLES}/${RoutePath.ASSET_DASHBOARD}/${RoutePath.PROPERTY}`,
  ROLES_CONSTRUCTION_HOME: `/${RoutePath.ROLES}/${RoutePath.CONSTRUCTION_MANAGER}`,
  ROLES_PROPERTY_HOME: `/${RoutePath.ROLES}/${RoutePath.PROPERTY_DASHBOARD}`,
  ROLES_VENDOR_HOME: `/${RoutePath.ROLES}/${RoutePath.VENDOR_DASHBOARD}`,

  RESIDENT: `/${RoutePath.RESIDENT}`,
  RESIDENT_SERVICE: `/${RoutePath.RESIDENT}/${RoutePath.SERVICE}`,
  RESIDENT_SERVICE_REQUEST: `/${RoutePath.RESIDENT}/${RoutePath.SERVICE}/${RoutePath.REQUEST}`,
  RESIDENT_SERVICE_UPCOMING: `/${RoutePath.RESIDENT}/${RoutePath.SERVICE}/${RoutePath.UPCOMING}`,
  RESIDENT_SERVICE_RATE: `/${RoutePath.RESIDENT}/${RoutePath.SERVICE}/${RoutePath.RATE}`,

  REPORTS: `/${RoutePath.REPORTS}`,
  REPORTS_RENT_ROLL: `/${RoutePath.REPORTS}/${RoutePath.RENT_ROLL}`,
  REPORTS_ROC: `/${RoutePath.REPORTS}/${RoutePath.ROC}`,
  REPORTS_BILLING: `/${RoutePath.REPORTS}/${RoutePath.BILLING}`,
  REPORTS_UNIT_MIX: `/${RoutePath.REPORTS}/${RoutePath.UNIT_MIX}`,
  REPORTS_DELINQUENCIES: `/${RoutePath.REPORTS}/${RoutePath.DELINQUENCIES}`,
  REPORTS_RENEWALS: `/${RoutePath.REPORTS}/${RoutePath.RENEWALS}`,

  DASHBOARD_ACTIVE_TURNOVERS: `/${RoutePath.UNIT_DASHBOARD}/${RoutePath.ACTIVE_TURNOVERS}`,
  DASHBOARD_TICKETS: `/${RoutePath.UNIT_DASHBOARD}/${RoutePath.TICKETS}`,
  DASHBOARD_PROPERTY_MGMT: `/${RoutePath.ASSET_DASHBOARD}/${RoutePath.PROPERTY}`,
  ASSET_MANAGER_HOME_DASHBOARD: `/${RoutePath.ASSET_DASHBOARD}`,
  PROPERTY_MANAGER_HOME_DASHBOARD: `/${RoutePath.PROPERTY_DASHBOARD}`,
  CONSTRUCTION_MANAGER_HOME_DASHBOARD: `/${RoutePath.CONSTRUCTION_MANAGER}`,
  ADMIN_HOME_DASHBOARD: `/${RoutePath.ADMIN_LEVEL_1}`,
  ADMIN_SUBSECTION_DASHBOARD: `/${RoutePath.ADMIN_LEVEL_2}`,

  INSPECTIONS: `/${RoutePath.INSPECTIONS}`,
  SETTINGS_SURVEYS: `/${RoutePath.INSPECTIONS}/${ManagementRoute.SURVEYS}`,
  SETTINGS_TEMPLATE: `/${RoutePath.INSPECTIONS}/${ManagementRoute.TEMPLATES}`,
  SETTINGS_SPACE_TEMPLATE: `/${RoutePath.INSPECTIONS}/${ManagementRoute.SPACE_TEMPLATE}`,

  SERVICE: `/${RoutePath.SERVICE}`,
  CALENDAR: `/${RoutePath.CALENDAR}`,

  LOGIN: `/${RoutePath.LOGIN}`,
  ACCEPT_TOKEN: `/${RoutePath.ACCEPT_TOKEN}`,

  WIDGET_BOARD_RESCHEDULE: `/${RoutePath.WIDGET_BOARD_RESCHEDULE}`,
  WIDGET_BOARD_DONE: `/${RoutePath.WIDGET_BOARD_DONE}`,
  WIDGET_BOARD_COMMENT: `/${RoutePath.WIDGET_BOARD_COMMENT}`,
  WIDGET_BOARD_TURNOVER_COMMENT: `/${RoutePath.WIDGET_BOARD_TURNOVER_COMMENT}`,
  WIDGET_BOARD_TURNOVER_DECLINE: `/${RoutePath.WIDGET_BOARD_TURNOVER_DECLINE}`,
  WIDGET_OPEN_MOBILE_APP: `/${RoutePath.OPEN_MOBILE_APP}`,
  WIDGET_DOWNLOAD_MOBILE_APP: `/${RoutePath.DOWNLOAD_MOBILE_APP}`,

  ROOT: `/`,
  BOARDS: `/${RoutePath.BOARDS}`,
  BUILDIUM: `/${RoutePath.BUILDIUM}`,
  TURNOVERS: `/${RoutePath.TURNOVERS}`,
  TURNOVERS_KANBAN: `/${RoutePath.TURNOVERS}/${RoutePath.KANBAN}`,
  TURNOVERS_ACTIVE: `/${RoutePath.TURNOVERS}/${RoutePath.ACTIVE_TURNOVERS}`,
  TURNOVERS_ARCHIVED: `/${RoutePath.TURNOVERS}/${RoutePath.ARCHIVED}`,
  TURNOVERS_NOTICE: `/${RoutePath.TURNOVERS}/${RoutePath.NOTICE}`,
  TURNOVERS_MOVE_OUT_CONFIRMATION: `/${RoutePath.TURNOVERS}/${RoutePath.MOVE_OUT_CONFIRMATION}`,
  TURNOVERS_SCOPE_AND_BID_APPROVAL: `/${RoutePath.TURNOVERS}/${RoutePath.BID}`,
  TURNOVERS_MAKE_READY: `/${RoutePath.TURNOVERS}/${RoutePath.MAKE_READY}`,
  TURNOVERS_RENOVATION: `/${RoutePath.TURNOVERS}/${RoutePath.RENO}`,
  TURNOVERS_PUNCH: `/${RoutePath.TURNOVERS}/${RoutePath.PUNCH}`,
  TURNOVERS_MANAGEMENT_APPROVAL: `/${RoutePath.TURNOVERS}/${RoutePath.APPROVAL}`,
  TURNOVERS_LEASE_READY: `/${RoutePath.TURNOVERS}/${RoutePath.MARKETING}`,
  TURNOVERS_LEASE_UP: `/${RoutePath.TURNOVERS}/${RoutePath.LEASE_UP}`,
  TURNOVERS_MOVE_IN: `/${RoutePath.TURNOVERS}/${RoutePath.MOVE_IN}`,
  TURNOVERS_ARCHIVE: `/${RoutePath.TURNOVERS}/${RoutePath.ARCHIVE}`,
  TURNOVERS_BULK_BIDS: `/${RoutePath.TURNOVERS}/${RoutePath.BULK_BIDS}`,
  TURNOVERS_DETAIL: `/${RoutePath.TURNOVERS}/:id`,
  JOBS_AVAILABLE_AND_BIDS_SENT: `/${RoutePath.JOBS_AVAILABLE_AND_BIDS_SENT}`,
  DELINQUENCIES: `/${RoutePath.DELINQUENCIES}`,
  DELINQUENCIES_DETAIL: `/${RoutePath.DELINQUENCIES}/:id`,
  RENEWALS: `/${RoutePath.RENEWALS}`,
  MOVE_OUT: `/${RoutePath.MOVE_OUT}`,
  MOVE_OUT_ASSET_MANAGER: `/${RoutePath.MOVE_OUT}/${RoutePath.ASSET_DASHBOARD}`,
  MOVE_OUT_PROPERTY_MANAGER: `/${RoutePath.MOVE_OUT}/${RoutePath.PROPERTY_DASHBOARD}`,
  MOVE_OUT_ASSET_MANAGER_ARCHIVED: `/${RoutePath.MOVE_OUT}/${RoutePath.ASSET_DASHBOARD}/${RoutePath.ARCHIVED}`,
  MOVE_OUT_PROPERTY_MANAGER_ARCHIVED: `/${RoutePath.MOVE_OUT}/${RoutePath.PROPERTY_DASHBOARD}/${RoutePath.ARCHIVED}`,
  RENEWALS_DETAIL: `/${RoutePath.RENEWALS}/:id`,
  SETTINGS: `/${ManagementRoute.SETTINGS}`,
  SETTINGS_USERS: `/${ManagementRoute.SETTINGS}/${ManagementRoute.USERS}`,
  SETTINGS_ROLES: `/${ManagementRoute.SETTINGS}/${ManagementRoute.ROLES}`,
  SETTINGS_ROLES_NEW: `/${ManagementRoute.SETTINGS}/${ManagementRoute.ROLES}/${ManagementRoute.NEW}`,
  SETTINGS_ROLES_EDIT: `/${ManagementRoute.SETTINGS}/${ManagementRoute.ROLES}/${ManagementRoute.EDIT}`,
  SETTINGS_RENEWAL_STRATEGY: `/${ManagementRoute.SETTINGS}/${ManagementRoute.RENEWAL_STRATEGY}`,
  SETTINGS_KPI: `/${ManagementRoute.SETTINGS}/${ManagementRoute.KPI}`,
  SETTINGS_PORTFOLIO: `/${ManagementRoute.SETTINGS}/${ManagementRoute.PORTFOLIO}`,
  SETTINGS_INSPECT: `/${ManagementRoute.SETTINGS}/${ManagementRoute.Inspect}`,
  SETTINGS_VENDOR: `/${ManagementRoute.SETTINGS}/${ManagementRoute.VENDOR}`,
  SETTINGS_SYSTEM: `/${ManagementRoute.SETTINGS}/${ManagementRoute.SYSTEM}`,
  SETTINGS_TURNOVER_CONFIGURATION: `/${ManagementRoute.SETTINGS}/${ManagementRoute.TURNOVER_CONFIGURATION}`,
  SETTINGS_SERVICE: `/${ManagementRoute.SETTINGS}/${ManagementRoute.SERVICE}`,
  TICKETS: `/${RoutePath.TICKETS}`,
  NO_PERMISSION: `/${RoutePath.NO_PERMISSION}`,
  NO_PACKAGE: `/${RoutePath.NO_PACKAGE}`,
};
